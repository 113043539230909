<template>
  <div class="dialog-container">
    <el-dialog
      title="云存储配置"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="720px">
      <el-form ref="form" :model="form" label-width="160px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="服务商" prop="type">
              <el-radio-group v-model="form.type">
                <el-radio :label="1">七牛云</el-radio>
                <el-radio :label="2">阿里云</el-radio>
                <el-radio :label="3">腾讯云</el-radio>
                <el-radio :label="4">壹体文件服务器</el-radio>
                <el-radio :label="5">华为云</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.type === 1">
          <el-col :span="24">
            <el-form-item label="域名：" prop="qiniuDomain">
              <el-input clearable v-model="form.qiniuDomain" placeholder="域名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="路径前缀：" prop="qiniuPrefix">
              <el-input clearable v-model="form.qiniuPrefix" placeholder="路径前缀"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="AccessKey：" prop="qiniuAccessKey">
              <el-input clearable v-model="form.qiniuAccessKey" placeholder="AccessKey"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="SecretKey：" prop="qiniuSecretKey">
              <el-input clearable v-model="form.qiniuSecretKey" placeholder="SecretKey"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="空间名：" prop="qiniuBucketName">
              <el-input clearable v-model="form.qiniuBucketName" placeholder="空间名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.type === 2">
          <el-col :span="24">
            <el-form-item label="域名：" prop="aliyunDomain">
              <el-input clearable v-model="form.aliyunDomain" placeholder="域名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="路径前缀：" prop="aliyunPrefix">
              <el-input clearable v-model="form.aliyunPrefix" placeholder="路径前缀"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="EndPoint：" prop="aliyunEndPoint">
              <el-input clearable v-model="form.aliyunEndPoint" placeholder="EndPoint"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="AccessKeyId：" prop="aliyunAccessKeyId">
              <el-input clearable v-model="form.aliyunAccessKeyId" placeholder="AccessKeyId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="AccessKeySecret：" prop="aliyunAccessKeySecret">
              <el-input clearable v-model="form.aliyunAccessKeySecret" placeholder="AccessKeySecret"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="BucketName：" prop="aliyunBucketName">
              <el-input clearable v-model="form.aliyunBucketName" placeholder="BucketName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.type === 3">
          <el-col :span="24">
            <el-form-item label="域名：" prop="qcloudDomain">
              <el-input clearable v-model="form.qcloudDomain" placeholder="域名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="路径前缀：" prop="qcloudPrefix">
              <el-input clearable v-model="form.qcloudPrefix" placeholder="路径前缀"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="腾讯云SecretId：" prop="qcloudSecretId">
              <el-input clearable v-model="form.qcloudSecretId" placeholder="腾讯云SecretId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="腾讯云SecretKey：" prop="qcloudSecretKey">
              <el-input clearable v-model="form.qcloudSecretKey" placeholder="腾讯云SecretKey"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="腾讯云BucketName：" prop="qcloudBucketName">
              <el-input clearable v-model="form.qcloudBucketName" placeholder="腾讯云BucketName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Bucket所属地区：" prop="qcloudRegion">
              <el-input clearable v-model="form.qcloudRegion" placeholder="设置bucket所在的区域，最新sdk不再支持简写，请填写完整"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.type === 4">
          <el-col :span="24">
            <el-form-item label="壹体文件服务器地址：" prop="oneSportsFileUrl">
              <el-input clearable v-model="form.oneSportsFileUrl" placeholder="域名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="存储环境文件夹：" prop="fileEnvir">
              <el-input clearable v-model="form.fileEnvir" placeholder="路径前缀"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="form.type === 5">
          <el-col :span="24">
            <el-form-item label="域名：" prop="hwcloudDomain">
              <el-input clearable v-model="form.hwcloudDomain" placeholder="域名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="路径前缀：" prop="hwcloudPrefix">
              <el-input clearable v-model="form.hwcloudPrefix" placeholder="路径前缀"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="华为云拼接路径前缀：" prop="hwFileNamePrefix">
              <el-input clearable v-model="form.hwFileNamePrefix" placeholder="华为云OBS拼接路径前缀"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="华为云SecretId：" prop="hwcloudSecretId">
              <el-input clearable v-model="form.hwcloudSecretId" placeholder="华为云SecretId"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="华为云SecretKey：" prop="hwcloudSecretKey">
              <el-input clearable v-model="form.hwcloudSecretKey" placeholder="华为云SecretKey"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="华为云BucketName：" prop="hwcloudBucketName">
              <el-input clearable v-model="form.hwcloudBucketName" placeholder="华为云BucketName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Bucket所属地区：" prop="hwcloudRegion">
              <el-input clearable v-model="form.hwcloudRegion" placeholder="设置bucket所在的区域，最新sdk不再支持简写，请填写完整"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      this.form = new AddDialogClass('form')
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post(apis.ossSaveConfig, this.form).then((res) => {
            if (res.data.code === 0) {
              // 调用关闭方法，不要再写一次
              this.closeDialog()
            }
          })
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery() {
      // 在这里获取回显的数据
      // this.form = { ...data }
      this.$http.post(apis.ossConfig).then((res) => {
        this.form = res.data
      })
    },
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
