<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-col :span="20">
          <el-form ref="form" :model="form" :inline="true">
            <div>
                <el-form-item label="文件名" class="form-item">
                  <el-input clearable v-model="form.fileName" placeholder="请输入文件名"></el-input>
                </el-form-item>
                <el-form-item label="文件后缀" class="form-item">
                  <el-input clearable v-model="form.fileSuffix" placeholder="请输入文件后缀"></el-input>
                </el-form-item>
                <el-form-item label="上传人" class="form-item">
                  <el-input clearable v-model="form.createBy" placeholder="请输入上传人"></el-input>
                </el-form-item>
            </div>
          </el-form>
        </el-col>
        <el-col class="search-btn-con" :span="4">
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="display: flex">
          <el-button style="margin-right: 10px" @click="changeAddDialog(true)">云存储配置</el-button>
          <el-upload
            style="margin-right: 10px"
            class="upload-demo"
            name="file"
            :httpRequest="uploadHttpDefault"
            :action="ImageUpload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :multiple="true">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <el-upload
            class="upload-demo"
            name="file"
            :data="params"
            :httpRequest="uploadHttpDefault"
            :action="staticImgUpload"
            :show-file-list="false"
            :on-success="staticSuccess"
            :before-upload="beforeAvatarUpload"
            :multiple="true">
            <el-button size="small" type="primary">上传静态资源</el-button>
          </el-upload>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        @sort-change='sort_post_change'
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column
					type="selection"
          align="center"
          fixed="left"
					width="55">
				</el-table-column>
        <el-table-column
					type="index"
          align="center"
          fixed="left"
          label="序号"
					width="55">
				</el-table-column>
				<el-table-column
					prop="id"
          align="center"
					label="文件编号"
          sortable='custom'
					width="180">
				</el-table-column>
				<el-table-column
					prop="fileName"
          align="center"
          width="220"
					label="文件名"
          sortable="custom"
          show-overflow-tooltip>
				</el-table-column>
				<el-table-column
					prop="fileSuffix"
          align="center"
					label="文件后缀"
          sortable='custom'
					width="180">
				</el-table-column>
				<el-table-column
					prop="url"
          align="center"
					label="文件预览"
					width="160">
          <template slot-scope="scope">
            <img :src="scope.row.url" alt="" width="40" @click="bigImg(scope.row.url)">
          </template>
				</el-table-column>
				<el-table-column
					prop="createBy"
          align="center"
					label="上传人"
          sortable='custom'
					width="180">
				</el-table-column>
				<el-table-column
					prop="service"
          align="center"
					label="服务商"
          sortable='custom'
					width="180">
          <template slot-scope="scope">
            <span>{{serveType(scope.row.service)}}</span>
          </template>
				</el-table-column>
        <el-table-column
          align="center"
					prop="createTime"
          sortable='custom'
          width="220"
					label="创建时间">
				</el-table-column>
        <el-table-column
          align="center"
          width="200px"
          fixed="right"
					:label="$t('option')">
          <template slot-scope="scope">
            <el-button class="default-button" @click="openEditDialog(scope.row)">{{$t('edit')}}</el-button>
            <el-button class="default-button" @click="openDeleteDialog(scope.row.id)">{{$t('delete')}}</el-button>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="bottom-con">
      <div class="button-container">
        <el-button class="default-button" @click="openDeleteDialog(selectedArr.map(item => item.id))">{{$t('delete')}}</el-button>
        <!-- <el-button class="default-button" @click="changeAddDialog(true)">{{$t('add')}}</el-button> -->
      </div>
      <div class="page-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total">
        </el-pagination>
      </div>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
    <!-- 删除确认弹窗 -->
    <edit-dialog ref="editDialog" :Language="Language" :show="editDialog.visible" :fileData="editDialog.data" @sureDialog="sureConfirmDialog" @closeDialog="changeEditDialog"></edit-dialog>
    <!-- 查看图片 -->
    <el-dialog
      title="图片详情"
      :visible.sync="imgDialog.visible"
      :before-close="imgClose"
      center
      :close-on-click-modal="false"
      width="500px">
      <img :src="url" alt="" width="100%">
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import UserSearchClass from './userSearchClass'
import apis from '@/apis'
import mixin from '@/mixins/index'
import addDialog from './dialog/addDialog.vue'
import editDialog from './dialog/editDialog.vue'
import confirmDialog from '@/components/confirmDialog.vue'

export default {
  components: {
    addDialog,
    confirmDialog,
    editDialog,
  },
  mixins: [mixin],
  data() {
    return {
      form: new UserSearchClass('form'), // 实例化一个表单的变量
      ImageUpload: apis.ImageUpload,
      // staticImgUpload: apis.staticImgUpload,
      staticImgUpload: '/fileApi/Assets/File/UploadNew',
      params: { directory: 'whszty' },
      tableData: [{}],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
      editDialog: {
        visible: false,
        data: {},
      },
      imgDialog: {
        visible: false,
      },
      url: '',
    }
  },
  computed: {
    ...mapState(['Language', 'TOKEN']),
    serveType() {
      return (type) => {
        let serveName = ''
        if (type === 1) {
          serveName = '七牛云'
        } else if (type === 2) {
          serveName = '阿里云'
        } else if (type === 3) {
          serveName = '腾讯云'
        } else if (type === 4) {
          serveName = '壹体服务器'
        } else if (type === 5) {
          serveName = '华为云'
        } else {
          serveName = ''
        }
        return serveName
      }
    },
  },
  methods: {
    //查看大图
    bigImg(url) {
      this.url = url
      this.imgDialog.visible = true
    },
    imgClose() {
      this.imgDialog.visible = false
      this.url = ''
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file)
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.searchData()
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!');
      }
      return !!isLt10M;
    },
    staticSuccess(res) {
      if (res.code === 200) {
        this.$message.success('上传成功！')
      } else {
        this.$message.error('上传失败！')
      }
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.ossList, {
        ...this.form,
      }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new UserSearchClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = true, data = '') {
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.addDialog.isEdit = isEdit
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs['addDialog'].getDataQuery(data)
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      console.log(this.confirmDialog.data)
      this.$http.delete(`${apis.ossRemove}?ids=${this.confirmDialog.data.ids.toString()}`).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
        }
      })
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      if (id.length === 0) {
        this.$message.error('请先选择数据')
        return
      }
      this.confirmDialog.data.ids = id
      this.changeConfirmDialog(true, 1)
    },
    /**
     * @function 打开编辑弹窗
     * @param {String} fileName 文件名
     */
    openEditDialog(data) {
      const { id, fileName } = data
      this.changeEditDialog(true, { id, fileName })
    },
    /**
     * @function 打开确认重置密码弹窗
     * @param {Array} data 要重置密码的值
     */
    openConfirmDialog(data) {
      this.confirmDialog.data = data
      this.changeConfirmDialog(true, 2)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent()
      }
    },
    changeEditDialog(dialogStatus = false, data = {}) {
      this.editDialog.data = data
      this.editDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.searchEvent()
      } else {
        this.$nextTick(() => {
          this.$refs.editDialog.getData(data)
        })
      }
    },
    /**
     * @function 修改用户启用状态
     * @param {Object} data 用户信息
     */
    changeStatus(data) {
      const form = {
        ...data,
        ...{
          status: data.status === '1' ? '0' : '1',
        },
      }
      this.$http.post(apis.userStatus, form)
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.form-item{
  margin-right: 30px;
}
.bottom-con{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  .button-container{
    width: auto;
  }
}
.search-btn-con{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
