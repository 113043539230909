
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.fileName = ''
    } else if (type === 'rule') {
      this.fileName = [
        {
          required: true,
          message: '请输入文件名',
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
