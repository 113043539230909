
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.id = ''
      this.type = 1
    } else if (type === 'rule') {
      this.id = [
        {
          required: true,
          message: '请输入编号',
          trigger: 'blur',
        },
      ]
    }
  }
}
export default searchFrom
